import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
// 全局过滤器 
Vue.filter('mynum', (text) => {
  return text + 3
})
new Vue({
  render: h => h(App),
}).$mount('#app')

