<template>
  <div id="app">
    <!-- <table border="1">
      <tr>
        <td>序号</td>
        <td>id</td>
        <td>姓名</td>
        <td>年龄</td>
      </tr>
      <tbody>
        <tr v-for="(item, index) in obj" :key="index">
          <td @click="ck($event, item.name)">{{ item.name }}</td>
          <td>{{ item.age }}</td>
        </tr>
      </tbody>
    </table>
    {{ arr }}
    <hr>
    {{ num | mynum }} -->

    <div class="app">
      <div class="son1 sn1"></div>
      <div class="son1 sn2"></div>
      <div class="son1 sn3">李晓菲是🐷</div>
    </div>
  </div>
</template>
<style>
.app {
  background: pink;
  width: 300px;
  height: 300px;
  top: 200px;
  margin: auto;
  position: relative;
  transform: rotate(-45deg);
}

.son1 {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: pink;
  position: absolute;
}

.sn1 {
  top: -150px;
}

.sn2 {
  left: 150px;
}

.sn3 {
  transform: translate(50px,-40px) rotate(45deg);
  margin: auto;
  text-align: center;
  line-height: 300px;
  font-size: 30px;
  font-weight: bold;

}
</style>
<script>
export default {
  name: 'ProjectApp',

  data() {
    return {
      arr: [1, 2, 3, 4, 5, 6, 7],
      obj: [{ name: '张三', age: 20 }, { name: '张三', age: 22 }, { name: '张三', age: 21 }, { name: '张三', age: 19 }, { name: '张三', age: 18 }, { name: '张三', age: 22 }, { name: '张三', age: 20 },],
      num: 1,
    };
  },

  mounted() {

  },

  methods: {
    ck(val, b) {
      console.log(val.target.innerHTML, b);
    }
  },
  filters: {
    mynum(text) {
      return text + 9
    },
  },
};

</script>
